import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./StockTable.css";

const StockTable = () => {
  const [stockData, setStockData] = useState([]);
  const [users, setUsers] = useState([
    { id: 1, balance: 1000000, portfolio: {}, region: "Hà Nội" },
    { id: 2, balance: 2000000, portfolio: {}, region: "Hồ Chí Minh" },
    { id: 3, balance: 1500000, portfolio: {}, region: "Đà Nẵng" },
    { id: 4, balance: 2500000, portfolio: {}, region: "Cần Thơ" },
    { id: 5, balance: 1800000, portfolio: {}, region: "Hải Phòng" },
  ]);
  const [order, setOrder] = useState({
    userId: 1,
    stockSymbol: "ACB",
    orderType: "LO",
    price: "",
    quantity: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = [
        {
          symbol: "ACB",
          tran: 10700,
          san: 9300,
          tc: 10000,
          gia3m: 0,
          kl3m: 0,
          gia2m: 0,
          kl2m: 0,
          gia1m: 0,
          kl1m: 0,
          pricekl: 0,
          klkl: 0,
          changekl: 0,
          percentChangekl: 0,
          gia1b: 0,
          kl1b: 0,
          gia2b: 0,
          kl2b: 0,
          gia3b: 0,
          kl3b: 0,
          total: 0,
        },
        {
          symbol: "VNM",
          tran: 69000,
          san: 60000,
          tc: 64500,
          gia3m: 0,
          kl3m: 0,
          gia2m: 0,
          kl2m: 0,
          gia1m: 0,
          kl1m: 0,
          pricekl: 0,
          klkl: 0,
          changekl: 0,
          percentChangekl: 0,
          gia1b: 0,
          kl1b: 0,
          gia2b: 0,
          kl2b: 0,
          gia3b: 0,
          kl3b: 0,
          total: 0,
        },
      ];
      setStockData(data);
    };
    fetchData();
  }, []);

  // Xử lý đặt lệnh Mua/Bán
  const handleOrder = (action) => {
    const { stockSymbol, price, quantity } = order;
    const updatedStockData = stockData.map((stock) => {
      if (stock.symbol === stockSymbol) {
        if (action === "buy") {
          let remainingQuantity = parseInt(quantity);
          const buyPrice = parseFloat(price);
  
          // Xử lý khớp lệnh với dư bán
          // nếu KL lệnh mua > 0 và giá mua >= giá bán 1 và KL bán 1 >0
          if (remainingQuantity > 0 && buyPrice >= stock.gia1b && stock.kl1b > 0) {
            // nếu KL lệnh mua > KL lệnh bán 1
            if (remainingQuantity >= stock.kl1b) {
              // Khớp khối lượng, trừ số lượng khớp bán
              remainingQuantity -= stock.kl1b;
              //klkl
              stock.klkl = stock.kl1b;
              stock.pricekl = stock.gia1b;
              // Chuyển giá 2 và 3 về 1 và 2
              stock.gia1b = stock.gia2b;
              stock.kl1b = stock.kl2b;
              stock.gia2b = stock.gia3b;
              stock.kl2b = stock.kl3b;
              stock.gia3b = 0;
              stock.kl3b = 0;
              
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1b;
              // nếu KL lệnh mua < KL lệnh bán 1 thì giảm khối lượng bán 1 xuống và khớp hết lệnh dư mua
              stock.kl1b -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc) * 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
          // nếu KL lệnh mua > 0 và giá mua >= giá bán 2 và KL bán 2 >0, lúc này giá 2 đã chuyển về giá 1 nên biến giá 1 chính là giá 2 cũ, và biến giá 2 là giá 3 cũ
          if (remainingQuantity > 0 && buyPrice >= stock.gia1b && stock.kl1b > 0) {
            if (remainingQuantity >= stock.kl2b) {
              //klkl
              stock.klkl = stock.kl1b;
              stock.pricekl = stock.gia1b;

              remainingQuantity -= stock.kl1b;
              stock.gia1b = stock.gia2b;
              stock.kl1b = stock.kl2b;
              stock.gia2b = 0;
              stock.kl2b = 0;
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1b;

              stock.kl1b -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc) * 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
          // nếu KL lệnh mua > 0 và giá mua >= giá bán 3 và KL bán 3 >0, lúc này giá 2 đã chuyển về giá 1 nên biến giá 1 chính là giá 2 cũ, và biến giá 2 là giá 3 cũ
          if (remainingQuantity > 0 && buyPrice >= stock.gia1b && stock.kl1b > 0) {
            if (remainingQuantity >= stock.kl1b) {
              //klkl
              stock.klkl = stock.kl1b;
              stock.pricekl = stock.gia1b;

              remainingQuantity -= stock.kl1b;
              stock.gia1b = 0;
              stock.kl1b = 0;
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1b;
              
              stock.kl1b -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc) * 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
  
          // Nếu còn dư lệnh mua, thêm vào dư mua
          if (remainingQuantity > 0) {
            const buyOrders = [
              { price: buyPrice, quantity: remainingQuantity },
              { price: stock.gia1m, quantity: stock.kl1m },
              { price: stock.gia2m, quantity: stock.kl2m },
              { price: stock.gia3m, quantity: stock.kl3m },
            ]
              .filter((order) => order.price > 0)
              .reduce((acc, order) => {
                const existing = acc.find((o) => o.price === order.price);
                if (existing) {
                  existing.quantity += order.quantity;
                } else {
                  acc.push(order);
                }
                return acc;
              }, [])
              .sort((a, b) => b.price - a.price);
  
            stock.gia1m = buyOrders[0]?.price || 0;
            stock.kl1m = buyOrders[0]?.quantity || 0;
            stock.gia2m = buyOrders[1]?.price || 0;
            stock.kl2m = buyOrders[1]?.quantity || 0;
            stock.gia3m = buyOrders[2]?.price || 0;
            stock.kl3m = buyOrders[2]?.quantity || 0;
          }

        } else if (action === "sell") {
          let remainingQuantity = parseInt(quantity);
          const sellPrice = parseFloat(price);
  
          // Xử lý khớp lệnh với dư mua
          if (remainingQuantity > 0 && sellPrice <= stock.gia1m && stock.kl1m > 0) {
            if (remainingQuantity >= stock.kl1m) {
              //klkl
              stock.klkl = stock.kl1m;
              stock.pricekl = stock.gia1m;

              remainingQuantity -= stock.kl1m;
              stock.gia1m = stock.gia2m;
              stock.kl1m = stock.kl2m;
              stock.gia2m = stock.gia3m;
              stock.kl2m = stock.kl3m;
              stock.gia3m = 0;
              stock.kl3m = 0;
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1m;

              stock.kl1m -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc)* 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
  
          if (remainingQuantity > 0 && sellPrice <= stock.gia1m && stock.kl1m > 0) {
            if (remainingQuantity >= stock.kl2m) {
              //klkl
              stock.klkl = stock.kl1m;
              stock.pricekl = stock.gia1m;

              remainingQuantity -= stock.kl2m;
              stock.gia1m = stock.gia2m;
              stock.kl1m = stock.kl2m;
              stock.gia2m = 0;
              stock.kl2m = 0;
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1m;

              stock.kl2m -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc)* 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
  
          if (remainingQuantity > 0 && sellPrice <= stock.gia1m && stock.kl1m > 0) {
            if (remainingQuantity >= stock.kl3m) {
              //klkl
              stock.klkl = stock.kl1m;
              stock.pricekl = stock.gia1m;

              remainingQuantity -= stock.kl1m;
              stock.gia1m = 0;
              stock.kl1m = 0;
            } else {
              //klkl
              stock.klkl = remainingQuantity;
              stock.pricekl = stock.gia1m;
              
              stock.kl3m -= remainingQuantity;
              remainingQuantity = 0;
            }
            // Cập nhật giá trị sau khớp lệnh
            stock.changekl = (stock.pricekl - stock.tc).toFixed(1); // Tính chênh lệch giá
            stock.percentChangekl = (((stock.pricekl - stock.tc) / stock.tc)* 100).toFixed(1) ; // Tính phần trăm thay đổi
          }
  
          // Nếu còn dư lệnh bán, thêm vào dư bán
          if (remainingQuantity > 0) {
            const sellOrders = [
              { price: sellPrice, quantity: remainingQuantity },
              { price: stock.gia1b, quantity: stock.kl1b },
              { price: stock.gia2b, quantity: stock.kl2b },
              { price: stock.gia3b, quantity: stock.kl3b },
            ]
              .filter((order) => order.price > 0)
              .reduce((acc, order) => {
                const existing = acc.find((o) => o.price === order.price);
                if (existing) {
                  existing.quantity += order.quantity;
                } else {
                  acc.push(order);
                }
                return acc;
              }, [])
              .sort((a, b) => a.price - b.price);
  
            stock.gia1b = sellOrders[0]?.price || 0;
            stock.kl1b = sellOrders[0]?.quantity || 0;
            stock.gia2b = sellOrders[1]?.price || 0;
            stock.kl2b = sellOrders[1]?.quantity || 0;
            stock.gia3b = sellOrders[2]?.price || 0;
            stock.kl3b = sellOrders[2]?.quantity || 0;
          }
        }
      }
      return stock;
    });
  
    setStockData(updatedStockData);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Bảng Giá Cổ Phiếu</h2>
      <div className="table-responsive">
        <table className="table table-bordered table-hover text-center">
          <thead className="table-light">
            <tr>
              <th>CK</th>
              <th>Trần</th>
              <th>Sàn</th>
              <th>TC</th>
              <th colSpan="6">Bên Mua</th>
              <th colSpan="3">Khớp Lệnh</th>
              <th colSpan="6">Bên Bán</th>
              <th>Tổng KL</th>
            </tr>
            <tr>
              <th colSpan="4"></th>
              <th>G3</th>
              <th>KL3</th>
              <th>G2</th>
              <th>KL2</th>
              <th>G1</th>
              <th>KL1</th>
              <th>GKL</th>
              <th>KLKL</th>
              <th>+/-(%)</th>
              <th>G1</th>
              <th>KL1</th>
              <th>G2</th>
              <th>KL2</th>
              <th>G3</th>
              <th>KL3</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stockData.map((stock, index) => (
              <tr key={index}>
                <td>{stock.symbol}</td>
                <td>{stock.tran}</td>
                <td>{stock.san}</td>
                <td>{stock.tc}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.gia3m}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.kl3m}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.gia2m}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.kl2m}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.gia1m}</td>
                <td style={{ backgroundColor: "#c9f9b2" }}>{stock.kl1m}</td>

                <td style={{ backgroundColor: "#5ec7f8" }}>{stock.pricekl}</td>
                <td style={{ backgroundColor: "#5ec7f8" }}>{stock.klkl}</td>
                <td style={{ backgroundColor: "#5ec7f8" }}>
                  {stock.changekl} ({stock.percentChangekl})
                </td>

                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.gia1b}</td>
                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.kl1b}</td>
                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.gia2b}</td>
                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.kl2b}</td>
                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.gia3b}</td>
                <td style={{ backgroundColor: "#f7f6c6" }}>{stock.kl3b}</td>
                <td>{stock.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="card p-3 mt-4">
        <h2 className="mb-3">Đặt Lệnh</h2>
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">Tài khoản:</label>
            <select
              className="form-select"
              value={order.userId}
              onChange={(e) => setOrder({ ...order, userId: e.target.value })}
            >
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  User {user.id}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Mã cổ phiếu:</label>
            <select
              className="form-select"
              value={order.stockSymbol}
              onChange={(e) =>
                setOrder({ ...order, stockSymbol: e.target.value })
              }
            >
              {stockData.map((stock) => (
                <option key={stock.symbol} value={stock.symbol}>
                  {stock.symbol}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Loại lệnh:</label>
            <select
              className="form-select"
              value={order.orderType}
              onChange={(e) => setOrder({ ...order, orderType: e.target.value })}
            >
              <option value="LO">LO</option>
              <option value="ATO">ATO</option>
              <option value="MP">MP</option>
            </select>
          </div>
          <div className="col-md-6">
            <label className="form-label">Giá:</label>
            <input
              type="number"
              className="form-control"
              value={order.price}
              onChange={(e) => setOrder({ ...order, price: e.target.value })}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Số lượng:</label>
            <input
              type="number"
              className="form-control"
              value={order.quantity}
              onChange={(e) => setOrder({ ...order, quantity: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-3 text-end">
          <button
            className="btn btn-primary me-2"
            onClick={() => handleOrder("buy")}
          >
            Mua
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleOrder("sell")}
          >
            Bán
          </button>
        </div>
      </div>
      <footer className="text-center mt-5 py-3">
        <p>Copyright by Nguyễn Thành Đông</p>
      </footer>
    </div>
  );
};

export default StockTable;
